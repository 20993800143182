<template>
  <el-table border :data="tableData" style="width: 100%" :row-class-name="tableRowClassName" :span-method="arraySpanMethod">
    <el-table-column prop="date" label="日期" width="180"> </el-table-column>
    <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
    <el-table-column prop="address" label="地址"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row)
      console.log(rowIndex)
      if (row.type === '2') {
        return 'warning-row'
      } else if (row.type === '3') {
        return 'success-row'
      }
      return ''
    },

    arraySpanMethod({ row, columnIndex }) {
      console.log(row)
      if (row.type === '2' || row.type === '3') {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        }
      }
    }
  },
  data() {
    return {
      tableData: [
        {
          date: '1',
          name: '王小虎',
          address: '10',
          type: '1'
        },
        {
          date: '2',
          name: '王小虎',
          address: '20',
          type: '1'
        },
        {
          date: '11',
          name: '王小虎小计',
          address: '30',
          type: '2'
        },
        {
          date: '3',
          name: '张三',
          address: '30',
          type: '1'
        },
        {
          date: '4',
          name: '张三',
          address: '40',
          type: '1'
        },
        {
          date: '22',
          name: '张三小计',
          address: '70',
          type: '2'
        },
        {
          date: '22',
          name: '合计',
          address: '100',
          type: '3'
        }
      ]
    }
  }
}
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
